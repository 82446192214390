import React from "react"

import styled from "styled-components"

const Footer = () => {
  return (
    <FooterWrapper>
      <p>Leopardis &#xa9;</p>
    </FooterWrapper>
  )
}

const FooterWrapper = styled.div`
  width: 100%;
  height: 3rem;
  background-color: #2d3436;
  background-image: linear-gradient(to bottom, #2d3436 0%, #000000 74%);

  background: #ffb347;
  background: -webkit-linear-gradient(to bottom, #ffcc33, #ffb347);
  background: linear-gradient(to bottom, #ffcc33, #ffb347);

  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: auto;

  p {
    margin: 0;
    padding: 0;
    color: white;
    font-family: "Pacifico", cursive;
    letter-spacing: 1px;
  }
`

export default Footer
