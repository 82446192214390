import * as React from "react"
import PropTypes from "prop-types"

import "bootstrap/dist/css/bootstrap.min.css"
import styled from "styled-components"
import Navigation from "../components/navbar"
import Footer from "../components/footer"

import "./layout.css"

const Layout = ({ children }) => (
  <AllWrapper>
    <Navigation />
    {children}
    <Footer />
  </AllWrapper>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const AllWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
`

export default Layout
