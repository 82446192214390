import * as React from "react"

import styled from "styled-components"

const Title = (props) => {
  return <SectionTitle>{props.title}</SectionTitle>
}

const SectionTitle = styled.h1`
  font-family: "Pacifico", cursive;
  text-align: center;
  margin: 2rem auto;
  color: #fbb034;

  background: #ffb347;
  background: -webkit-linear-gradient(to bottom, #ffcc33, #ffb347);
  background: linear-gradient(to bottom, #ffcc33, #ffb347);
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;

  height: 4rem;

  @media (max-width: 540px) {
    margin: 1rem auto;
  }

  ::selection {
    background-color: #fbb034;
    color: #170e00;
  }
`

export default Title
