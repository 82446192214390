import React from "react"
import { Link } from "gatsby"

import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"

import styled from "styled-components"

const Navigation = () => {
  return (
    <NavbarWrapper bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/">Leopardis</Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <NavLinksWrapper className="me-auto">
            {/* <Nav.Link> */}
            <Link to="/" activeClassName="active">
              Zucht
            </Link>
            {/* </Nav.Link> */}
            <Link to="/kitten" activeClassName="active">
              Kitten
            </Link>
            <Link to="/kontakt" activeClassName="active">
              Kontakt
            </Link>
            <Link to="/impressum" activeClassName="active">
              Impressum
            </Link>
          </NavLinksWrapper>
        </Navbar.Collapse>
      </Container>
    </NavbarWrapper>
  )
}

// style={{ margin: "0", borderBottom: "3px solid #fbb034" }}
const NavbarWrapper = styled(Navbar)`
  margin: 0;
  border-bottom: 3px solid #ffb347;
  font-size: 1.25rem;
  height: auto;

  @media (max-width: 991.98px) {
    border-bottom: none;
  }
`

const NavLinksWrapper = styled(Nav)`
  display: flex;
  width: 100%;

  justify-content: space-evenly;
  align-items: center;
  text-align: center;

  a:link,
  a:visited {
    color: #170e00;
    text-decoration: none;
    padding: 20px 50px;
    padding: 1rem 2rem;
  }
  a:hover {
    color: #ffb347 !important;
  }

  .active {
    color: #ffb347 !important;
  }

  /* .nav-link a:link,
  .nav-link a:visited {
    color: #170e00;
    text-decoration: none;
  }

  .nav-link a:hover {
    color: #ffb347 !important;
  } */
`

export default Navigation
